<template>
  <div>
    <b-row>
      <b-col>
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <b-card v-else>
          <b-row>
            <!-- User Info: Left col -->
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="user.avatar ? user.avatar.image : null"
                  :text="user.name"
                  :variant="`light-primary`"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1 text-truncate">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      {{ user.name }}
                    </h4>
                    <span class="card-text">{{ user.email }}</span>
                  </div>
                  <div class="d-flex flex-wrap">
                    <b-button
                      :to="{ name: 'users-edit', params: { id: user.uuid } }"
                      variant="primary"
                    >
                      Modifier
                    </b-button>
                    <b-button
                      v-b-modal="`modal-delete-user-${user.uuid}`"
                      variant="outline-danger"
                      class="ml-0 ml-sm-1"
                      :disabled="deleteLoading"
                    >
                      Supprimer
                    </b-button>
                    <b-modal
                      :id="`modal-delete-user-${user.uuid}`"
                      :ref="`modal-delete-user-${user.uuid}`"
                      ok-variant="danger"
                      ok-title="Supprimer"
                      modal-class="modal-danger"
                      centered
                      title="Suppression de l'utilisateur"
                    >
                      <b-card-text>
                        Souhaitez vous vraiment supprimer cet utilisateur ?<br>
                        Ces éléments seront supprimés :<br>
                        -Messages<br>
                        -Pronostics (sur les matchs et les questions)<br>
                        -Les points du joueur seront remis à 0 (ainsi que le
                        classement)
                        <p class="text-danger font-weight-bold">
                          (Cette action est réversible)
                        </p>
                      </b-card-text>
                      <template #modal-footer>
                        <b-button
                          variant="secondary"
                          @click="hideModal()"
                        >
                          annuler
                        </b-button>
                        <b-button
                          variant="danger"
                          :disabled="deleteLoading"
                          @click="deleteUser()"
                        >
                          <b-spinner
                            v-show="deleteLoading"
                            small
                          />
                          supprimer
                        </b-button>
                      </template>
                    </b-modal>
                  </div>
                </div>
              </div>

              <!-- User Stats -->
              <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center mr-2">
                  <b-avatar
                    variant="light-primary"
                    rounded
                  >
                    <feather-icon
                      icon="LayoutIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      <b-link
                        :to="{
                          name: 'platforms-view',
                          params: {
                            id: user.platform ? user.platform.uuid : null,
                          },
                        }"
                      >
                        {{ user.platform ? user.platform.name : "" }}
                      </b-link>
                    </h5>
                    <small>Plateforme</small>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <b-avatar
                    variant="light-success"
                    rounded
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ moment(user.created_at).format("LLL") }}
                    </h5>
                    <small>Date de création</small>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="6"
              class="text-truncate"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Nom</span>
                  </th>
                  <td class="pb-50">
                    {{ user.name }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="AtSignIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Email</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ user.email }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="MapPinIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Location</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ user.location }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Compte verfié</span>
                  </th>
                  <td class="pb-50">
                    <b-badge
                      :variant="`light-${
                        user.email_verified ? 'success' : 'danger'
                      }`"
                    >
                      {{ user.email_verified ? "oui" : "non" }}
                    </b-badge>
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="MailIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Newsletters</span>
                  </th>
                  <td class="pb-50">
                    <b-badge
                      :variant="`light-${
                        user.newsletters ? 'success' : 'danger'
                      }`"
                    >
                      {{ user.newsletters ? "inscrit" : "non-inscrit" }}
                    </b-badge>
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="BellIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Notifications</span>
                  </th>
                  <td class="pb-50">
                    <b-badge
                      :variant="`light-${
                        user.notification ? 'success' : 'danger'
                      }`"
                    >
                      {{ user.notification ? "oui" : "non" }}
                    </b-badge>
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="BookIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Tutoriel</span>
                  </th>
                  <td class="pb-50">
                    <b-badge
                      :variant="`light-${user.tutorial ? 'success' : 'danger'}`"
                    >
                      {{ user.tutorial ? "oui" : "non" }}
                    </b-badge>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <h3 class="text-center mb-3 mt-1">
      Messages
    </h3>
    <UserViewMessages />
    <h3 class="text-center mb-3 mt-1">
      Pronostics
    </h3>
    <UsersViewForecasts />
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BRow,
  BCol,
  BAvatar,
  BButton,
  BSpinner,
  BCardText,
  BBadge,
  BModal,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import UserViewMessages from '@/views/users/users-view/UsersViewMessages'
import UsersViewForecasts from '@/views/users/users-view/UsersViewForecasts'
import axiosIns from '@/libs/axios'

export default {
  name: 'UsersView',
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BLink,
    BAvatar,
    BButton,
    BSpinner,
    BCardText,
    BModal,
    UserViewMessages,
    UsersViewForecasts,
  },
  data() {
    return {
      loading: true,
      deleteLoading: false,
      apiUrl: 'users',
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
    }),
  },
  mounted() {
    this.fetchUser()
  },
  beforeDestroy() {
    this.$store.commit('app/UPDATE_PAGE_META', {
      pageTitle: '',
      breadcrumb: [],
    })
  },
  methods: {
    async fetchUser() {
      try {
        this.loading = true
        await this.$store.dispatch('users/fetchUser', this.$route.params.id)
        this.$store.commit('app/UPDATE_PAGE_META', {
          pageTitle: this.user.name,
          breadcrumb: [
            {
              text: 'Utilisateurs',
              active: false,
              to: {
                name: 'users',
              },
            },
            {
              text: this.user.name,
              active: true,
            },
          ],
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async deleteUser() {
      try {
        this.deleteLoading = true
        await axiosIns.delete(`users/${this.user.uuid}`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: "L'utilisateur a été supprimé",
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
        this.hideModal()
        await this.$router.push({
          path: '/users',
        })
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
      }
    },
    hideModal() {
      this.$refs[`modal-delete-user-${this.user.uuid}`].hide()
    },
  },
}
</script>

<style scoped></style>
