<template>
  <div>
    <b-card no-body>
      <messages-list
        :api-url="apiUrl"
        :table-columns="tableColumns"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import MessagesList from '@/components/lists/messages/MessagesList.vue'

export default {
  name: 'UsersViewMessages',
  components: {
    BCard,
    MessagesList,
  },
  created() {
    this.apiUrl = `users/${this.$route.params.id}/messages`
  },
  data() {
    return {
      tableColumns: [
        {
          key: 'content',
          label: 'Message',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Date de création',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      apiUrl: 'messages',
    }
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
