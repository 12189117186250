<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Afficher</label>
            <v-select
              v-model="perPage"
              :dir="'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="fetchForecasts(true)"
            />
            <label>entrées</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form @submit.prevent="fetchForecasts(true)">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Rechercher..."
                />
                <b-button
                  type="submit"
                  variant="primary"
                >
                  <span class="text-nowrap">Chercher</span>
                </b-button>
              </div>
            </b-form>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refForecastListTable"
        class="position-relative"
        :items="forecasts"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucun résultat trouvé"
        :sort-desc.sync="isSortDirDesc"
        :no-local-sorting="true"
        :busy="loading"
        @sort-changed="sortForecasts($event)"
      >
        <!-- busy -->
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner label="Loading..." />
          </div>
        </template>
        <!-- Column: date_time -->
        <template #cell(date_time)="data">
          <span>{{ moment(data.value).format("LLL") }}</span>
        </template>
        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge :variant="resolveStatusVariant(data.item.status)">
            {{ data.item.status }}
          </b-badge>
        </template>
        <!-- Column: pts_won -->
        <template #cell(pts_won)="data">
          <template v-if="isForecastQuestion(data.item) && data.item.question.forecast">
            {{ data.item.question.forecast.pts_won }} / {{ data.item.question.points_to_win }}
          </template>
          <template v-if="isForecastFixture(data.item) && data.item.fixture.forecast">
            {{ data.item.fixture.forecast.pts_won }} / {{ pointsToWin(data.item.fixture) }}
          </template>
        </template>
        <!-- Column: entitled -->
        <template #cell(entitled)="data">
          <template v-if="isForecastQuestion(data.item)">
            <span> {{ data.item.question.title['fr'] }} </span>
          </template>
          <template v-if="isForecastFixture(data.item) && data.item.fixture.local_team && data.item.fixture.visitor_team">
            <span>{{ data.item.fixture.local_team.name['fr'] }} vs {{ data.item.fixture.visitor_team.name['fr'] }}</span>
          </template>
        </template>
        <!-- Column: forecast -->
        <template #cell(forecast)="data">
          <template v-if="isForecastQuestion(data.item)">
            <b-row
              v-if="data.item.question.forecast"
              class="row align-items-center"
            >
              <b-col>
                <!--                <b-badge :variant="resolveStatusGoodForecast(data.item.question.forecast.is_good_forecast)">
                  {{ data.item.question.forecast.is_good_forecast ? 'Bon prono' : 'Mauvais prono' }}
                </b-badge>-->
                <span> {{ data.item.question.forecast.answer ?data.item.question.forecast.answer.title['fr'] : '' }} </span>
                <b-img
                  v-if="data.item.question.forecast.answer"
                  height="auto"
                  width="50"
                  :src="data.item.question.forecast.answer.image"
                  alt="Question img"
                />
              </b-col>
            </b-row>
          </template>
          <template v-if="isForecastFixture(data.item)">
            <span v-if="data.item.fixture.forecast">
              {{ data.item.fixture.forecast.local_team_score }} - {{ data.item.fixture.forecast.visitor_team_score }}
              <b-img
                v-if="data.item.fixture.forecast.winner_team"
                height="auto"
                width="15"
                :src="data.item.fixture.forecast.winner_team.logo_path"
              />
            </span>
          </template>
        </template>
        <!-- Column: detail -->
        <template #cell(detail)="data">
          <template v-if="isForecastQuestion(data.item)">
            <feather-icon
              :id="`forecast-row-${data.item.uuid}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer"
              size="16"
              @click="$router.push({ name: 'questions-view', params: { id: data.item.question.uuid }})"
            />
            <b-tooltip
              title="Détails"
              class="cursor-pointer"
              :target="`forecast-row-${data.item.uuid}-show-icon`"
            />
          </template>
          <template v-if="isForecastFixture(data.item)">
            <feather-icon
              :id="`forecast-row-${data.item.uuid}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer"
              size="16"
              @click="$router.push({ name: 'questions-view'})"
            />
            <b-tooltip
              title="Détails"
              class="cursor-pointer"
              :target="`forecast-row-${data.item.uuid}-show-icon`"
            />
          </template>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
              {{ dataMeta.total }} entrées</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalForecasts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePage($event)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BPagination,
  BSpinner,
  BForm, BModal, BCardText, BTooltip, BBadge, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { avatarText } from '@core/utils/filter'

export default {
  name: 'UsersViewForecasts',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BSpinner,
    BForm,
    vSelect,
    BModal,
    BCardText,
    BTooltip,
    BBadge,
    BImg,
  },
  setup() {
    const resolveStatusVariant = status => {
      if (status === 'PENDING') return 'light-info'
      if (status === 'RUNNING') return 'light-success'
      if (status === 'FINISHED') return 'light-warning'
      return 'light-success'
    }
    const resolveStatusGoodForecast = bool => (bool ? 'light-success' : 'light-danger')
    const isForecastQuestion = forecast => {
      if (forecast.type === 'QUESTION' && forecast.question) return true
      return false
    }
    const isForecastFixture = forecast => {
      if (forecast.type === 'FIXTURE' && forecast.fixture) return true
      return false
    }
    const pointsToWin = fixture => {
      const { stage } = fixture
      if (stage === '8th Finals' || stage === 'Quarter-finals' || stage === 'Semi-finals' || stage === '3rd Place Final' || stage === 'Final') {
        return '6'
      }
      return '3'
    }
    return {
      resolveStatusVariant,
      resolveStatusGoodForecast,
      avatarText,
      isForecastQuestion,
      isForecastFixture,
      pointsToWin,
    }
  },
  data() {
    return {
      loading: true,
      forecasts: [],
      perPageOptions: [5, 25, 50, 100],
      perPage: 25,
      searchQuery: '',
      sortBy: 'date_time',
      isSortDirDesc: false,
      tableColumns: [
        {
          key: 'date_time',
          label: 'Date',
          sortable: true,
        },
        {
          key: 'status',
          label: 'status',
          sortable: true,
        },
        {
          key: 'pts_won',
          label: 'points gagnés',
          sortable: false,
        },
        {
          key: 'entitled',
          label: 'Intitulé',
          sortable: false,
        },
        {
          key: 'forecast',
          label: 'Pronostic',
          sortable: false,
        },
        {
          key: 'detail',
          label: 'Détail',
          sortable: false,
        },
      ],
      currentPage: 1,
      totalForecasts: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
        total: 0,
      },
    }
  },
  mounted() {
    this.fetchForecasts()
  },
  methods: {
    async fetchForecasts(clearPagination = false) {
      try {
        this.loading = true
        const { data } = await axiosIns.get(`users/${this.$route.params.id}/forecasts`, {
          params: {
            term: this.searchQuery,
            page: clearPagination ? 1 : this.currentPage,
            perPage: this.perPage,
            sortBy: this.sortBy,
            sortDirection: this.isSortDirDesc ? 'desc' : 'asc',
          },
        })
        this.forecasts = data.data
        this.dataMeta.from = data.meta.from
        this.dataMeta.to = data.meta.to
        this.dataMeta.total = data.meta.total
        this.totalForecasts = data.meta.total
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    sortForecasts(e) {
      this.isSortDirDesc = e.sortDesc
      this.sortBy = e.sortBy
      this.fetchForecasts()
    },
    changePage(e) {
      this.currentPage = e
      this.fetchForecasts()
    },
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
